<template>
  <div>
    <h3>Importar Productos</h3>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p>Importa producto y precios</p>
          </v-col>
          <v-col>
            <v-file-input
              class="mt-2"
              outlined
              v-model="archivo"
              :rules="reglas.archivo"
              accept=".data"
              label="Archivo.data"
            ></v-file-input>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn :disabled="archivo == null" :loading="inicializando" @click="cargarArchivo()" color="primary"> Subir </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
 
<script>
import { ref } from '@vue/composition-api'
import ExportImportServices from '@/api/servicios/ExportImportServices'
import store from '@/store'
export default {
  components: {},
  setup() {
    const archivo = ref(null)
    const reglas = {
      archivo: [value => !value || extraerExtencion(value.name) == 'inic' || 'No es una extencion valia'],
    }
    const inicializando = ref(false)

    const cargarArchivo = () => {
      inicializando.value = true

      const form = new FormData()
      form.append('Archivo', archivo.value)
      //SUBIR IMAGEN
      // console.log('asq')
      ExportImportServices.ImportProductoSucursal(form)
        .then(response => {
          console.log(response.data)
          if (response.data.estatus == true) {
            archivo.value = null
            store.commit('setAlert', {
              message: response.data.datos.mensajeError,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.datos.mensajeError,
              type: 'error',
            })
            console.log(response.data)
          }
          //imagen = response.data.img
        })
        .catch(error => {
          

          store.commit('setAlert', {
              message: 'error catch ' + error,
              type: 'error',
            })
        })
        .finally(() => {
          inicializando.value = false
        })

      //dataPrevImg.value = [];
    }
    return {
      reglas,
      archivo, 
      cargarArchivo,
      inicializando,
    }
  },
}
</script>
 